import { GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import { CellRenderer } from '../../../../../Components/Formik';
import { GroupModelKeys } from '../../../../../Models/Groups';
import { getFormattedDateWithYear, roundToPlaces } from '../../../../../Utils';

const {
  renderEditGroupByGroupIdCell,
  renderGroupActionCell,
  renderGroupStatusCell,
} = CellRenderer;

export const columns = [
  {
    field: GroupModelKeys.Id,
    headerName: 'ID',
    description: 'ID',
    renderCell: (params: GridRenderCellParams) =>
      renderEditGroupByGroupIdCell(params),
  },
  {
    field: GroupModelKeys.UserName,
    headerName: 'UserName',
    description: 'UserName',
    renderCell: (params: GridRenderCellParams) =>
      renderEditGroupByGroupIdCell(params),
    flex: 2,
  },
  {
    field: GroupModelKeys.Email,
    headerName: 'Email',
    description: 'Email',
    flex: 4,
  },
  {
    field: GroupModelKeys.NumMembers,
    headerName: 'Members',
    flex: 2,
    description: 'Members',
  },
  {
    field: GroupModelKeys.JobsAdded,
    headerName: 'JobsAdded',
    flex: 2,
    description: 'JobsAdded',
  },
  {
    field: GroupModelKeys.JobsDone,
    headerName: 'JobsDone',
    flex: 2,
    description: 'JobsDone',
  },
  {
    field: GroupModelKeys.AvgDistance,
    headerName: 'AvgDistance',
    description: 'AvgDistance',
    renderCell: (params: GridRenderCellParams) =>
      roundToPlaces(params.value, 0),
    flex: 2,
  },
  {
    field: GroupModelKeys.LastLoginDate,
    headerName: 'Last Login',
    description: 'Last Login',
    flex: 2,
    renderCell: (params: GridRenderCellParams) =>
      getFormattedDateWithYear(params.value),
  },
  {
    field: GroupModelKeys.Active,
    headerName: 'Status',
    flex: 2,
    description: 'Status',
    renderCell: (params: GridRenderCellParams) =>
      renderGroupStatusCell(params),
    },
  {
    field: 'action',
    headerName: 'Action',
    flex: 2,
    description: 'Action',
    type: 'actions',
    getActions: (params: GridRowParams) => renderGroupActionCell(params),
  },
];
