import {
  DataGrid,
  GridSortModel,
} from '@mui/x-data-grid';
import CSS from 'csstype';
import { ControlledPagination as Pagination } from '../../../../../Components';
import { GroupsResponseModel } from '../../../../../Models/Groups';
import { GridProps, Sorter } from '../../../../../Types';
import { getSortModelFromSorter } from '../../../../../Utils';
import { columns } from './Columns';

const GroupsGridView: React.FC<GroupsGridProps> = (props) => {
  const { data: groups, onPageChange, onPageSizeChange, onSort } = props;

  const handlePageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    currentPage: number
  ) => {
    onPageChange(currentPage);
  };

  const handleRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onPageSizeChange(parseInt(e.target.value));
  };

  const handleSort = (sortModel: GridSortModel) => {
    onSort(sortModel);
  };

  const { Page: page, TableSorter } = groups;
  if (page) {
    const { CurrentPage, TotalPages, ItemsPerPage, TotalItems, Items } = page;
    const CustomGridToolbar = () => {
      return (
        <Pagination
          itemsCount={TotalItems}
          currentPage={CurrentPage}
          totalPages={TotalPages}
          pageSize={ItemsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        ></Pagination>
      );
    };
    return (
      <div>
        {page && (
          <div id='id_grid_groups' className="groups-container">
            <DataGrid
              sortingOrder={['asc', 'desc']}
              getRowId={(row: any) => row.Id}
              autoHeight
              disableColumnMenu={true}
              rows={Items}
              rowHeight={41}
              columns={columns}
              components={{
                Toolbar: CustomGridToolbar,
                Footer: CustomGridToolbar,
              }}
              sortingMode="server"
              sortModel={getSortModelFromSorter(TableSorter as Sorter)}
              onSortModelChange={handleSort}
            />
          </div>
        )}
      </div>
    );
  }
  return null;
};

export default GroupsGridView;

interface GroupsGridProps extends GridProps<GroupsResponseModel> {}

const imageStyle: CSS.Properties = { height: '25px', width: '25px' };
